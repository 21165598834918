
// Add smily emojies into list of emojies
export const listOfEmojies = [
  "✨",
  "🌟",
  "🔥",
  "🌈",
  "🎉",
  "🎈",
  "🎊",
  "😊",
  "😃",
  "😄",
  "😁",
  "😆",
  "😍",
  "😎",
  "😜",
  "😉",
  "😘",
  "😋",
  "😂",
  "😇",
  "😊",
  "😀",
  "😅",
  "😌",
  "😚",
  "😝",
  "😛",
  "😏",
  "😙",
  "😺",
  "😸",
  "😻",
  "😼",
  "😽",
  "😹",
  "😿",
  "😾",
  "😽",
  "😼",
  "😻",
  "😸",
  "😺",
  "😙",
  "😏",
  "😛",
  "😝",
  "😚",
  "😌",
  "😅",
  "😀",
  "😊",
  "😇",
  "😂",
  "😋",
  "😘",
  "😉",
  "😜",
  "😎",
  "😍",
  "😆",
  "😁",
  "😄",
  "😃",
  "😊",
  ];
